<template>
  <div>
    <NavTitle title="岗位轮转">
      <template #right>
        <div class="flex pr20">
          <span :class="['mr10', isOpen == 1 ? 'theme-text':'']">{{isOpen == 1 ? '已启用':'已停用'}}</span>
          <el-switch :value="isOpen" :active-value="1" :inactive-value="0" @change="statusChange"></el-switch>
        </div>
      </template>
    </NavTitle>
    <div class="menu-wrap ml20 mr20 mt16 pr16">
      <el-menu  :default-active="menuType" mode="horizontal" active-text-color="#08C8BD" @select="menuSelect">
        <el-menu-item :index="item.type" v-for="(item, index) in menuList" :key="index" style="position:relative;">{{item.name}}</el-menu-item>
      </el-menu>
      <el-button type="primary" @click="$refs.settingRecomRef.open({type: Number(menuType)})">
        <div class="flex items-center">
          <img class="icon-add" src="@/assets/images/add-fill-w.png">
          <span>添加岗位组</span>
        </div>
      </el-button>
    </div>
    <div class="pl20 pr20 mt16" v-loading="loading">
      <el-table
        :data="configList"
        style="width: 100%"
        border
        :header-cell-style="{background: '#F8F8F8', color: '#333'}">
        <el-table-column 
          prop="postIds"
          label="轮转岗位(岗位ID)"
          header-align="center">
          <el-table-column
            v-for="(item, index) in idLength"
            :key="index"
            :label="`排名${index+1}`"
            header-align="center"
            align="center"
            width="110">
            <template slot-scope="scope">
              <span class="blue-text pointer" @click="$refs.postDetailRef.open({pid: scope.row.idObj[`id${index}`]})" v-if="scope.row.idObj[`id${index}`]">{{scope.row.idObj[`id${index}`]}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column 
          prop="frequencyDesc"
          label="轮转频率"
          header-align="center"
          width="150"
          align="center">
        </el-table-column>
        <!-- <el-table-column 
          label="轮转/锁定渠道"
          header-align="center"
          width="130">
          <template slot-scope="scope">
            <div class="pointer" style="color: #199EFF; text-align:center;" @click="$refs.channelRef.open(scope.row)">查看</div>
          </template>
        </el-table-column> -->
        <el-table-column
        label="轮转渠道"
        header-align="center"
        min-width="200">
          <template slot-scope="scope">
            <div class="ch-list" v-if="scope.row.roundChs && scope.row.roundChs.length > 0">
              <div class="ch" v-for="(item, index) in scope.row.roundChs" :key="index">
                {{item.productName}}{{item.ch}}
                <span class="theme-text" v-if="index == 0">(默认)</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
        label="锁定渠道"
        header-align="center"
        min-width="200">
          <template slot-scope="scope">
            <div class="ch-list" v-if="scope.row.lockChs && scope.row.lockChs.length > 0">
              <div class="ch" v-for="(item, index) in scope.row.lockChs" :key="index">{{item.productName}}{{item.ch}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column 
          label="操作"
          header-align="center"
          width="320"
          fixed="right">
          <template slot-scope="scope">
            <div class="flex items-center">
              <span class="pointer theme-text" @click="toEdit(scope.row)">设置岗位推荐</span>
              <div class="flex between-center ml16 mr16">
                <span :class="{'theme-text': scope.row.status == 1}">{{scope.row.status == 0 ? '已停用':'已启用'}}</span>
                <el-switch class="ml10" :value="scope.row.status" :active-value="1" :inactive-value="0" @change="configStatusChange($event, scope.row)"></el-switch>
              </div>
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="handleDelete(scope.row)"></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 查看渠道 -->
    <ChannelPopup ref="channelRef"></ChannelPopup>
    <!-- 设置轮转 -->
    <SettingRecomPopup ref="settingRecomRef" @update="getConfigList"></SettingRecomPopup>
    <!-- 职位详情 -->
    <PostDetailPopup ref="postDetailRef"></PostDetailPopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import ChannelPopup from './components/ChannelPopup.vue'
import SettingRecomPopup from './components/SettingRecomPopup.vue'
import PostDetailPopup from '@/components/PostDetailPopup'
import { postRoundManageApi } from '@/api/admin.js'
export default {
  name: 'PostRotate',
  components: {
    NavTitle,
    ChannelPopup,
    SettingRecomPopup,
    PostDetailPopup
  },
  data() {
    return {
      isOpen: false,
      menuList: [
        {name:'开店', type:'1'},
        {name:'转发', type:'4'},
        {name:'语音', type:'2'},
        {name:'剪辑', type:'3'},
        {name:'线上推广', type:'5'},
      ],
      menuType: '1',
      configList: [],
      idLength: 0,
      loading: false
    }
  },
  created(){
    this.getConfigList()
  },
  methods: {
    menuSelect(val) {
      this.menuType = val
      this.getConfigList()
    },
    getConfigList() {
      const formData = {
        act: 0,
        composeType: Number(this.menuType)
      }
      console.log('formData', formData)
      this.loading = true
      postRoundManageApi(formData).then(res => {
        console.log('岗位轮转', res)
        this.loading = false
        this.isOpen = res.data.status
        const list = res.data.roundUnits || []
        if(list.length > 0) {
          let idlength = 0
          list.forEach(item => {
            if(item.postIds?.length > idlength) idlength = item.postIds.length
            const obj = {}
            item.postIds?.forEach((id,index) => {
              obj[`id${index}`] = id
            })
            Object.assign(item, {
              idObj: obj
            })
          })
          this.idLength = idlength
        }
        this.configList = list
      }).catch(err => {
        this.loading = false
        this.$tips({message: err, type: 'error'})
      })
    },
    statusChange(val) {
      console.log(val)
      const formData = {
        act: val == 1 ? 3 : 4,
      }
      console.log('formData',formData)
      postRoundManageApi(formData).then(res => {
        this.isOpen = val
        this.$tips({message: res.msg, type: 'success'})
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    configStatusChange(e, info) {
      console.log(e)
      const formData = {
        composeType: Number(this.menuType),
        act: 2,
        roundUnit: {
          frequency: info.frequency,
          frequencyDesc: info.frequencyDesc,
          roundChs: info.roundChs,
          lockChs: info.lockChs,
          postIds: info.postIds,
          status: e,
          uniqueId: info.uniqueId
        }
      }
      if(this.isEdit) Object.assign(formData.roundUnit, {uniqueId: this.uniqueId})
      console.log('formData', formData)
      postRoundManageApi(formData).then(res => {
        console.log('设置结果', res)
        this.$tips({message: res.msg, type: 'success'})
        this.getConfigList()
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    handleDelete(info) {
      this.$confirm('确定删除该配置吗？').then(action => {
        if(action == 'confirm') {
          const formData = {
            composeType: Number(this.menuType),
            act: 5,
            roundUnit: {
              uniqueId: info.uniqueId
            }
          }
          postRoundManageApi(formData).then(res => {
            console.log('删除', res)
            this.$tips({message: res.msg, type: 'success'})
            this.getConfigList()
          }).catch(err => {
            this.$tips({message: err, type: 'error'})
          })
        }
      }).catch(() => {})
    },
    toEdit(info) {
      this.$refs.settingRecomRef.open({
        type: Number(this.menuType),
        isEdit: true,
        detail: info
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  .icon-add {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}
.ch-list {
  display: flex;
  flex-wrap: wrap;
  .ch {
    height: 34px;
    line-height: 34px;
    padding: 0 12px;
    border-radius: 17px;
    border: 1px solid #E8E8E8;
    font-size: 14px;
    margin: 0 6px 12px 0;
    color: #001A18;
  }
}
</style>